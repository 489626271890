import { useRoutesContext } from '@chegg-wt/core/components/RoutesContext'
import React, { FC } from 'react'
import { useRouter } from 'next/router'
import SEOTitle from '@chegg-wt/core/components/SEOTitle'
import { decodeQueryParam, isSameOrigin } from '@chegg-wt/core/utils/url'
import useSBMCheck from '@chegg-wt/core/hooks/useSBMCheck'
import BackgroundIFrame from '@chegg-wt/core/components/Auth/background'
import OneAuth from '@chegg-wt/core/components/Auth'
import { AppState } from '@chegg-wt/core/state/types'
import { connect } from 'react-redux'
import { useOnUnloadTimeOnPage } from '@chegg/time-on-page'
import {
  backgroundIFrameUrl,
  backgroundThroughGamSelector,
} from '@chegg-wt/core/state/featureFlags/selectors'

type QueryParams = {
  redirect?: string
}

interface LoginProp {
  backgroundThroughGam: boolean
  backgroundIFrameUrl: string
}

const Login: FC<LoginProp> = ({ backgroundThroughGam, backgroundIFrameUrl }) => {
  useSBMCheck()
  const router = useRouter()
  const { redirect } = router.query as QueryParams
  const decodedRedirect = redirect && decodeQueryParam(redirect)
  const { toFolders } = useRoutesContext()
  const redirectTo =
    decodedRedirect && isSameOrigin(decodedRedirect)
      ? { href: decodedRedirect }
      : toFolders()
  useOnUnloadTimeOnPage()

  return (
    <>
      <SEOTitle title="Login | EasyBib" />
      <OneAuth redirectTo={redirectTo} backgroundThroughGam={backgroundThroughGam} />
      {backgroundThroughGam && (
        <BackgroundIFrame backgroundIFrameUrl={backgroundIFrameUrl} />
      )}
    </>
  )
}
const mapStateToProps = (state: AppState) => ({
  backgroundThroughGam: backgroundThroughGamSelector(state),
  backgroundIFrameUrl: backgroundIFrameUrl(state),
})

export default connect(mapStateToProps)(Login)
